import { lazy } from 'react';
import ReactDOM from 'react-dom/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const blocks: { [name: string]: any } = {
  root: lazy(() => import('../xpack/root/Root')),
  'blog-grid': lazy(() => import('@organisms/blog-grid/BlogGrid')),
  'eshop-business-partner-popup': lazy(() => import('@organisms/eshop/EShopBusinessPartnerPopup')),
  'search-result-list': lazy(() => import('@organisms/eshop/SearchResultList')),
  'favorite-list': lazy(() => import('@organisms/eshop/FavoriteList')),
  'search-list': lazy(() => import('@organisms/eshop/SearchList')),
  'shopping-cart-list': lazy(() => import('@organisms/eshop/ShoppingCartList')),
  'product-list-filter-sort': lazy(() => import('@organisms/product-list-filter-sort-block/ProductListFilterSortBlock')),
  productList: lazy(() => import('@organisms/product/ProductCardList')),
  'checkout-main-area': lazy(() => import('@organisms/eshop/EShopCheckoutMainArea')),
  'add-to-cart-button': lazy(() => import('@molecules/eshop/AddToCartButton')),
  'order-history': lazy(() => import('@organisms/eshop/EShopOrderHistoryMainArea')),
  'order-detail': lazy(() => import('@organisms/eshop/EShopOrderDetailMainArea')),
  'order-confirmation': lazy(() => import('@organisms/eshop/EShopOrderConfirmationMainArea')),
  'space-requirement-detail': lazy(() => import('@organisms/space-requirement-detail/SpaceRequirementDetailMainArea')),
  'architect-portal-download': lazy(() => import('@organisms/architect-portal-download/ArchitectDownload')),
  imageHotspot: lazy(() => import('@organisms/image-hotspot/ImageHotspot')),
  favoritePageList: lazy(() => import('@organisms/favorite-page-list/FavoritePageList')),
  assetLibraryContainer: lazy(() => import('@organisms/asset-library/AssetLibraryContainer')),
};

const loadComponent = (scriptSection: HTMLScriptElement) => {
  const blockType = scriptSection.getAttribute('data-rct');
  const data = scriptSection.textContent ? scriptSection.textContent : '{}';

  if (!blockType || !data) {
    return;
  }

  const Component = blocks[blockType];
  if (Component) {
    scriptSection.textContent = null;
    const section = document.createElement(scriptSection.getAttribute('data-tag') ?? 'section');
    section.className = scriptSection.getAttribute('data-class') ?? '';
    scriptSection.replaceWith(section);

    const props = JSON.parse(data);
    ReactDOM.createRoot(section).render(<Component {...props} />);
  } else {
    return <></>;
  }
};

const loadComponents = () => {
  // rct stands for 'react component type'
  const scriptSections = document.querySelectorAll('script[data-rct]');

  [].forEach.call(scriptSections, loadComponent);
};

loadComponents();
window.addEventListener('load', () => loadComponents());
